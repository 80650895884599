
<template>
  <div class="row">
    <div class="col-12">
      <h4 class="mb-3 text-center">General</h4>
    </div>
    <div class="col-6 mt-4">
      <h4>REPORT STATUS</h4>
      <table class="table text-center table-sm small e-tbl">
        <thead class="bg-white">
          <tr>
            <th>REPORT STATUS</th>
            <th>COUNT</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="reportStatus in pscReportStatusCount">
            <td class="align-middle font-weight-bolder">{{reportStatus.name}}</td>
            <td class="align-middle font-weight-bolder">{{reportStatus.report_status_count}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="col-6 mt-4">
      <h4>BOUNTY</h4>
      <table class="table text-center table-sm small e-tbl">
        <thead class="bg-white">
        <tr>
          <th>YEAR</th>
          <th>AMOUNT</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="bounty in pscBounties">
          <td class="align-middle font-weight-bolder">{{bounty.inspection_year}} TOTAL BOUNTY =</td>
          <td class="align-middle font-weight-bolder">USD ${{bounty.total_bounty}}</td>
        </tr>
        </tbody>
      </table>
    </div>
<!--    <div class="col-6">-->
<!--      <div class="card p-2">-->
<!--        <div class="card-title">-->
<!--          <h4>PSC SUMMARY REPORT</h4>-->
<!--        </div>-->
<!--        <div class="card-body py-0">-->
<!--          <div class="form-row align-items-center">-->
<!--            <div class="form-group col-md-6">-->
<!--              <label for="inputEmail4">Year</label>-->
<!--              <select name="month" id="month" class="form-control form-control-sm" v-model="params.year">-->
<!--                <option :value="null">-&#45;&#45; SELECT YEAR -&#45;&#45;</option>-->
<!--                <option :value="year" v-for="year in years">{{year}}</option>-->
<!--              </select>-->
<!--            </div>-->
<!--            <div class="form-group col-md-6">-->
<!--              <label for="inputPassword4">Deficiency</label>-->
<!--              <select name="summary-year" id="summary-year" class="form-control form-control-sm">-->
<!--                <option :value="null">ALL PSC RECORD</option>-->
<!--                <option :value="1">WITH DEFICIENCY</option>-->
<!--                <option :value="0">WITHOUT DEFICIENCY</option>-->
<!--              </select>-->
<!--            </div>-->
<!--          </div>-->
<!--          <div class="text-right">-->
<!--            <button class="e-btn e-btn-blue-gray" @click="generateCsv">Generate CSV</button>-->
<!--          </div>-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->
  </div>
</template>

<style scoped>

</style>
<script>
import {mapActions, mapGetters} from "vuex";
import {ExcelService} from '@/services/ExcelService';

export default {
  name: "PscDashboardGeneral",
  data(){
    return {
      params:{
        year:new Date().getFullYear(),
        with_deficiency:null,
      },
      years:Array.from({length:100},(_,i)=>new Date().getFullYear() - i)
    }
  },
  computed:{
    ...mapGetters(['pscReportStatusCount','pscBounties'])
  },
  created() {
    this.getPscReportStatusCount();
    this.getPscBounties();
  },
  methods:{
    ...mapActions(['getPscReportStatusCount','getPscBounties']),
    async generateCsv(){
      const params = Object.assign({},this.params);

      if(params.with_deficiency === null) {
        delete params.with_deficiency
      }

      swal.fire({
        'title' : 'GENERATING CSV...'
      })
      swal.showLoading()
      await ExcelService.csv('PSC SUMMARY REPORT',params);
      swal.close()
    }
  }
}
</script>

